import React from 'react';
import L from 'leaflet';
import mapMarkerIcon from '../images/mapMarkerIcon.png';
import useBreakPoint from '../hooks/useBreakPoint';

const Map = React.forwardRef(
  (
    {
      center = { lat: 0, lng: 0 },
      zoom = 1,
      scrollWheelZoom = true,
      markers = null,
      markerCoordinates = null,
      popupContent = null,
      className,
    },
    ref
  ) => {
    const { isMd, isLg, 'is1.125xl': is1_125xl } = useBreakPoint();
    const leafletMapRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
      zoomInTo(coords) {
        leafletMapRef.current.flyTo(coords, 14, {
          animate: true,
          duration: 1.5,
        });
      },
      zoomOutTo(coords) {
        const zoomLevel = is1_125xl ? 6 : isLg ? 5 : isMd ? 5 : 4;
        leafletMapRef.current.flyTo(coords, zoomLevel, {
          animate: true,
          duration: 1.5,
        });
      },
    }));

    React.useEffect(() => {
      // create a Leaflet map
      leafletMapRef.current = L.map('mapid', {
        scrollWheelZoom,
        zoomControl: false,
      }).setView([center.lat, center.lng], zoom);

      // move zoom control to top right to aviod being blocked by text layer in 768px
      L.control.zoom({ position: 'topright' }).addTo(leafletMapRef.current);

      // use Mapbox tiles to have a better street view
      L.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        }
      ).addTo(leafletMapRef.current);

      // define our own marker icon
      let markerIcon = L.icon({
        iconUrl: mapMarkerIcon,
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -35],
      });

      if (markers && Array.isArray(markers)) {
        // add multiple markers and popups to the map
        markers
          .filter(({ coordinates }) => !!coordinates)
          .forEach(({ coordinates, address }) => {
            L.marker(coordinates, { icon: markerIcon })
              .addTo(leafletMapRef.current)
              .bindPopup(address);
          });
      } else {
        if (markerCoordinates) {
          // add marker to the map
          let marker = L.marker(markerCoordinates, { icon: markerIcon }).addTo(
            leafletMapRef.current
          );
          // add popup to the marker
          if (popupContent) {
            marker.bindPopup(popupContent).openPopup();
          }
        }
      }
      // cleanup
      return () => {
        if (leafletMapRef.current && leafletMapRef.current.remove) {
          leafletMapRef.current.off();
          leafletMapRef.current.remove();
        }
      };
    }, [
      center,
      zoom,
      scrollWheelZoom,
      markers,
      markerCoordinates,
      popupContent,
    ]);

    return <div id="mapid" className={className}></div>;
  }
);

export default Map;

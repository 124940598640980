import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h2 className="bg-techsicoBlue-800 text-white font-rubik text-xl pl-5 py-4 pb-10 lg:pb-20 md:text-2xl md:pl-9 md:py-5.5 lg:text-3.5xl 1.125xl:text-5xl 3xl:text-6xl 3xl:leading-20 lg:py-7.5 lg:pl-30 1.125xl:py-9 1.125xl:pl-38 3xl:py-12 3xl:pl-48">
        Privacy Policy
      </h2>
      <div className="font-montserrat px-12 pt-16 lg:px-50 1.5xl:px-96 3xl:px-100 lg:leading-6">
        <h2 className="text-lg lg:text-2xl font-bold">Who we are</h2>
        <p>
          Our website address is{' '}
          <a className="underline" href="https://techsico.com">
            https://techsico.com
          </a>
          .
        </p>
        <br />
        <h2 className="text-lg lg:text-2xl font-bold pb-2">
          What personal data we collect and why we collect it
        </h2>
        <h3 className="text-md lg:text-xl font-bold">Comments</h3>
        <p className="text-sm lg:text-base">
          When visitors leave comments on the site, we collect the data shown in
          the comments form and also the visitor's IP address and browser user
          agent string to help spam detection. An anonymized string created from
          your email address (also called a hash) may be provided to the
          Gravatar service to see if you are using it. The Gravatar service
          privacy policy is available here:{' '}
          <a className="underline" href="https://automattic.com/privacy/">
            https://automattic.com/privacy/
          </a>
          .
        </p>
        <br />
        <h3 className="text-md lg:text-xl font-bold">Media</h3>
        <p className="text-sm lg:text-base">
          If you upload images to the website, you should avoid uploading images
          with embedded location data (EXIF GPS) included. Visitors to the
          website can download and extract any location data from images on the
          website.
        </p>
        <br />
        <h3 className="text-md lg:text-xl font-bold">Contact forms</h3>
        <p className="text-sm lg:text-base">
          If you use a contact form on this website, you are consenting for us
          to use the information you provide to contact you regarding business
          matters. Your IP address and browser user agent string will be
          collected and used to help spam detection.
        </p>
        <div>
          <br />
          <h3 className="text-md lg:text-xl font-bold">Cookies</h3>
          <div className="space-y-3 text-sm lg:text-base">
            <p>
              If you leave a comment on our site, you may opt-in to saving your
              name, email address, and website in cookies. These are for your
              convenience so that you do not have to fill in your details again
              when you leave another comment. These cookies will last for one
              year.
            </p>
            <p>
              If you visit our login page, we will set a temporary cookie to
              determine if your browser accepts cookies. This cookie contains no
              personal data and is discarded when you close your browser.
            </p>
            <p>
              When you log in, we will also set up several cookies to save your
              login information and your screen display choices. Login cookies
              last for up to ten days and screen options cookies last for a
              year. If you select "Remember Me", your login will persist for two
              weeks. If you log out of your account, the login cookies will be
              removed.
            </p>
            <p>
              If you edit or publish an article, an additional cookie with be
              saved in your browser. This cookie includes no personal data and
              simply indicates the post ID of the article you just edited. It
              expires after one day.
            </p>
          </div>
        </div>
        <br />
        <h3 className="text-md lg:text-xl font-bold">
          Embedded Content From Other Websites
        </h3>
        <div className="space-y-3 text-sm lg:text-base">
          <p>
            Articles on this site may include embedded content (e.g. videos,
            images, articles, etc.). Embedded content from other websites
            behaves in the exact same way as if the visitor has visited the
            other website.
          </p>
          <p>
            These websites may collect data about you, use cookies, embed
            additional third-party tracking, and monitor your interaction with
            that embedded content, including tracking your interaction with the
            embedded content if you have an account and are logged in to that
            website.
          </p>
        </div>
        <br />
        <h3 className="text-md lg:text-xl font-bold">Analytics</h3>
        <div className="space-y-3 text-sm lg:text-base">
          <p>
            We use internal and third-party analytic services such as Google
            Analytics to better understand what happens on our site. This
            information is used to better design the layout and other features
            of the site with the intent of making it easier for customers and
            employees to navigate and find the information they need.
          </p>
          <p>
            You can learn more about Google Analytics privacy information here:{' '}
            <a
              className="underline"
              href="https://policies.google.com/privacy?hl=en-US"
            >
              https://policies.google.com/privacy?hl=en-US
            </a>
          </p>
        </div>
        <br />
        <h3 className="text-md lg:text-xl font-bold">
          Who we share your data with
        </h3>
        <div className="space-y-3 text-sm lg:text-base">
          <p>
            Data collected is inteded for internal company use only and is not
            shared with third-parties other than the data collection services we
            use. To see their policy, please visit their websites.
          </p>
          <div>
            <p>Currently, we use:</p>
            <p>Google Analytics</p>
          </div>
        </div>
        <br />
        <h3 className="text-md lg:text-xl font-bold">
          How long we retain your data
        </h3>
        <div className="space-y-3 text-sm lg:text-base">
          <p>
            If you leave a comment or fill out a web form, the comment or other
            information and its metadata are retained indefinitely. This is so
            we can recognize and approve any follow-up comments automatically
            instead of holding them in a moderation queue.
          </p>
          <p>
            For users that register on our website (if any), we also store the
            personal information they provide in their user profile. All users
            can see, edit, or delete their personal information at any time
            (except they cannot change their username). Website administrators
            can also see and edit that information.
          </p>
        </div>
        <br />
        <h3 className="text-md lg:text-xl font-bold">
          What rights you have over your data
        </h3>
        <p className="text-sm lg:text-base">
          If you have an account on this site or have left comments, you can
          request to receive an exported file of the personal data we hold about
          you, including any data you have provided to us. You can also request
          that we erase any personal data we hold about you. This does not
          include any data we are obliged to keep for administrative, legal, or
          security purposes.
        </p>
        <br />
        <h3 className="text-md lg:text-xl font-bold">
          Where we send your data
        </h3>
        <p className="text-sm lg:text-base">
          Visitor comments may be checked through an automated spam detection
          service.
        </p>
        <br />
        <h3 className="text-md lg:text-xl font-bold">
          Your contact information
        </h3>
        <p className="text-sm lg:text-base">
          Contact information can at any time be forwared on to our sales or
          management team for followup.
        </p>
        <br />
        <h2 className="text-lg lg:text-2xl font-bold pb-2">
          Additional information
        </h2>
        <h3 className="text-md lg:text-xl font-bold">
          How we protect your data
        </h3>
        <p className="text-sm lg:text-base">
          Your data is stored in secured facilities with up-to-date software and
          operating system. When new security vulnerabilities are found that
          could affect the safety of your data, we work hard to ensure these
          threats are minimized. Only authorized and trusted personnel are given
          access to the systems that contain your data.
        </p>
        <br />
        <h3 className="text-md lg:text-xl font-bold">
          What data breach procedures we have in place
        </h3>
        <p className="text-sm lg:text-base pb-20">
          Your data will be protected and if a breach is detected, the affected
          users will be notified.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

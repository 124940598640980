import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    isDark: false,
  },
  reducers: {
    darken: state => {
      state.isDark = true;
    },
    lighten: state => {
      state.isDark = false;
    },
    toggleTheme: state => {
      state.isDark = !state.isDark;
    },
  },
});

export const { darken, lighten, toggleTheme } = themeSlice.actions;
export const selectTheme = state => state.theme.isDark;
export default themeSlice.reducer;

import React from 'react';
import TechsicoLogo from './TechsicoLogo';
import './Card.css';
import useBreakPoint from '../hooks/useBreakPoint';

const Card = ({
  title = 'Lorem Ipsum',
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac leo aliquet, suscipit ante a, accumsan quam. Duis libero elit, lobortis vel elit semper, ornare mollis nunc. Cras tincidunt feugiat felis in finibus. Pellentesque nec ante sed nulla aliquet consequat sit amet sed nunc. Nulla mollis convallis nibh. Sed sed diam volutpat ex pulvinar sollicitudin id facilisis tellus. Quisque ornare urna vitae ipsum vulputate, sit amet iaculis turpis volutpat. Nullam et risus elit. Vestibulum est metus, cursus a ex ac, rutrum euismod dui.',
  color = 'techsicoBlue-800',
  hoverColor = 'techsicoBlue-500',
}) => {
  const { isMd, isLg, 'is1.125xl': is1_125xl } = useBreakPoint();
  const [isExpanded, setIsExpanded] = React.useState(false);
  // chop an article into paragraphs
  const paragraphs = content
    .split('\n')
    .map(p => p.trim())
    .filter(p => !!p);

  return (
    <div className="p-5 relative overflow-hidden md:px-24 md:py-14 lg:px-50 lg:py-24 1.125xl:px-68 1.125xl:py-36 3xl:px-88 3xl:py-48">
      <h2
        className={`text-${color} font-rubik text-3xl mt-8 mb-4 md:mt-0 lg:text-4xl lg:mb-6 1.125xl:text-5xl 1.125xl:mb-8 3xl:text-6xl`}
      >
        {title.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </h2>
      <div
        className={`webkit-box overflow-hidden transition-maxHeight duration-1000 text-sm leading-5.5 text-gray-500 font-montserrat font-light lg:text-lg lg:leading-6.25 1.125xl:text-2xl 1.125xl:leading-8.5 3xl:text-3xl 3xl:leading-10.5 ${
          isExpanded
            ? 'max-h-screen line-clamp-none'
            : `max-h-49 md:max-h-28 lg:max-h-31 1.125xl:max-h-42 3xl:max-h-52 ${
                isMd ? 'delay-clamp-to-5' : 'delay-clamp-to-8'
              }`
        }`}
      >
        {paragraphs.map((paragraph, index) => (
          <p
            key={index}
            className={`line-space contents ${
              is1_125xl
                ? 'line-space-xl'
                : isLg
                ? 'line-space-lg'
                : 'line-space-sm'
            }`}
          >
            {paragraph}
          </p>
        ))}
      </div>
      <button
        className={`bg-${color} hover:bg-${hoverColor} uppercase font-rubik text-sm text-white font-bold p-4 mb-8 rounded-2xl mt-4 md:mb-0 lg:text-lg lg:mt-6 1.125xl:text-xl 1.125xl:mt-8 3xl:text-2xl`}
        onClick={() => {
          setIsExpanded(isExpanded => !isExpanded);
        }}
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
      <TechsicoLogo
        color={color}
        opacity="0.1"
        className="absolute -bottom-16 -right-16 w-66 h-66 md:w-80 md:h-80 lg:w-112 lg:h-112 lg:-bottom-24 lg:-right-24 1.125xl:w-164 1.125xl:h-164 1.125xl:-bottom-36 1.125xl:-right-36 3xl:w-220 3xl:h-220 3xl:-bottom-48 3xl:-right-48"
      />
    </div>
  );
};

export default Card;

import useMediaQuery from './useMediaQuery';

const useBreakpoints = () => ({
  isMd: useMediaQuery('(min-width: 768px)'),
  isLg: useMediaQuery('(min-width: 1024px)'),
  'is1.125xl': useMediaQuery('(min-width: 1440px'),
  is3xl: useMediaQuery('(min-width: 1920px)'),
});

export default useBreakpoints;

import React from 'react';
import './App.css';
import useUpdateDOMTheme from './hooks/useUpdateDOMTheme';
import techsicoHeaderLogo from './images/techsicoHeaderLogo.svg';
import facebookLogo from './images/facebookLogo.svg';
import twitterLogo from './images/twitterLogo.svg';
import instagramLogo from './images/instagramLogo.svg';
import linkedinLogo from './images/linkedinLogo.svg';
import techsicoLogoVertical from './images/techsicoLogoVertical.svg';
import TechsicoPortal, { TECHSICO_LOGO } from './components/TechsicoPortal';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import Home from './pages/Home';
import Locations from './pages/Locations';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  useUpdateDOMTheme();
  const isHomeRoute = useRouteMatch({ path: '/', exact: true });

  return (
    <div className="App min-h-screen flex flex-col">
      <header className="flex flex-row items-center p-5 h-20 md:px-9 md:h-24 lg:px-30 lg:h-32 1.125xl:px-38 1.125xl:h-36 3xl:px-48 3xl:h-44 dark:bg-gray-500 dark:text-white">
        <div className="w-48 md:w-52 lg:w-64 1.125xl:w-80 3xl:w-112">
          <Link to="/" className="text-left">
            <img src={techsicoHeaderLogo} alt="Techsico" />
          </Link>
        </div>
        <div className="flex-grow"></div>
        <a
          href="https://www.techsicode.com"
          target="_blank"
          rel="noreferrer nofollow"
          className="uppercase w-24 text-center md:text-right text-techsicoRed-700 font-rubik font-medium leading-5 md:text-sm md:w-32 hover:underline dark:text-white lg:text-lg lg:w-40 1.125xl:text-xl 1.125xl:w-48 3xl:text-2xl 3xl:w-56"
        >
          Employee Login
        </a>
      </header>

      <div className="flex-grow">
        <main className="flex-grow">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/locations">
              <Locations />
            </Route>
            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
          </Switch>

          {/* second portal for the websites */}
          <section
            className={`bg-techsicoGray-200 md:flex md:flex-row md:px-16 lg:px-20 1.125xl:px-26 3xl:px-30 ${
              isHomeRoute ? 'hidden' : 'block flex flex-col'
            }`}
          >
            <div className="flex md:flex-row flex-col items-center">
              <img
                src={techsicoLogoVertical}
                alt="Techsico Logo"
                className="mt-8 -mb-8 w-30 h-30 md:my-0 md:w-25 md:h-25 lg:w-36 lg:h-38 1.125xl:w-44 1.125xl:h-48 3xl:w-80 3xl:h-80"
              />
            </div>
            <div className="grid grid-cols-4 mt-16 md:mt-0 md:flex-grow md:gap-4 md:ml-12 lg:ml-14 1.125xl:ml-24 3xl:ml-36">
              <TechsicoPortal
                logo={TECHSICO_LOGO.TECHSICO}
                subtitle="Enterprise"
                underlineColor="enterpriseRed-500"
                link={process.env.REACT_APP_TES_LINK}
              />
              <TechsicoPortal
                logo={TECHSICO_LOGO.TECHSICOINC}
                subtitle="Inc."
                underlineColor="wirelessRed-50"
                link={process.env.REACT_APP_TOW_LINK}
              />
              <TechsicoPortal
                logo={TECHSICO_LOGO.TECHSICO_NATIVE}
                subtitle="Native"
                underlineColor="nativeBlue-200"
                link={process.env.REACT_APP_TNT_LINK}
              />
              <TechsicoPortal
                logo={TECHSICO_LOGO.TECHSICO}
                subtitle="Solar"
                underlineColor="techsicoBlue-900"
                link={process.env.REACT_APP_SOL_LINK}
              />
            </div>
          </section>
          {/* connect with us */}
          <section className="p-5 text-center bg-techsicoBlue-800 text-white flex flex-col md:flex-row lg:px-25 1.125xl:px-35 1.125xl:py-20 3xl:px-43 3xl:py-24">
            <div className="m-4">
              <h2 className="text-2xl leading-7 pt-2 font-rubik font-bold md:text-left md:text-xl md:leading-6 lg:text-2xl lg:leading-7 1.125xl:text-3xl 3xl:text-4xl">
                Connect With Us
              </h2>
              <div className="flex flex-row justify-center md:justify-start lg:mt-2.5">
                <a
                  href="https://www.facebook.com/TECHSICO"
                  target="_blank"
                  rel="noreferrer"
                  title="Click to navigate to Techsico Facebook page"
                >
                  <img
                    src={facebookLogo}
                    alt="Facebook Icon"
                    className="m-3 w-7.5 rounded-md hover:bg-techsicoBlue-500 md:w-5 md:ml-0 lg:w-7.5 1.125xl:w-10 3xl:w-12"
                  />
                </a>
                <a
                  href="https://twitter.com/TECHSICO"
                  target="_blank"
                  rel="noreferrer"
                  title="Click to navigate to Techsico Twitter page"
                >
                  <img
                    src={twitterLogo}
                    alt="Twitter Icon"
                    className="m-3 w-7.5 rounded-md hover:bg-techsicoBlue-500 md:w-5 lg:w-7.5 1.125xl:w-10 3xl:w-12"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/techsico-inc/"
                  target="_blank"
                  rel="noreferrer"
                  title="Click to navigate to Techsico LinkedIn page"
                >
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn Icon"
                    className="m-3 w-7.5 rounded-md hover:bg-techsicoBlue-500 md:w-5 lg:w-7.5 1.125xl:w-10 3xl:w-12"
                  />
                </a>
                <a
                  href="https://www.instagram.com/techsicoinc/"
                  target="_blank"
                  rel="noreferrer"
                  title="Click to navigate to Techsico Instagram page"
                >
                  <img
                    src={instagramLogo}
                    alt="Instagram Icon"
                    className="m-3 w-7.5 rounded-md hover:bg-techsicoBlue-500 md:w-5 lg:w-7.5 1.125xl:w-10 3xl:w-12"
                  />
                </a>
              </div>
            </div>
            <div className="md:flex-grow"></div>
            <div className="m-4">
              <p className="text-xl leading-6 pt-2 font-rubik font-bold lg:text-2xl lg:leading-7 lg:mb-2.5 1.125xl:text-3xl 3xl:text-4xl">
                Mon-Fri | 8am-5pm
              </p>
              <p className="text-xl leading-6 py-3 font-rubik font-bold md:text-right lg:text-2xl lg:leading-7 1.125xl:text-3xl 3xl:text-4xl">
                <a href="tel:+1-918-720-0123" className="hover:underline">
                918-720-0123
                </a>
              </p>
            </div>
          </section>
        </main>

        <footer className="p-4 bg-techsicoRed-700 text-white text-center bg-gray-200 dark:bg-gray-500 dark:text-gray-100 flex flex-col md:flex-row md:px-5 lg:px-25 lg:py-7 1.125xl:px-35 1.125xl:py-9 3xl:px-43">
          <div className="pb-3 text-xs leading-3.5 font-rubik font-bold md:pb-0 md:px-4 lg:text-base 1.125xl:text-xl 3xl:text-2xl">
            &copy;
            {` ${new Date().getFullYear()} `}
            <span className="uppercase">Techsico</span>. All rights reserved.
          </div>
          <div className="md:flex-grow"></div>
          <Link
            to="/privacypolicy"
            className="text-xs leading-3.5 font-rubik font-bold hover:underline md:px-4 lg:text-base 1.125xl:text-xl 3xl:text-2xl"
          >
            Privacy Policy
          </Link>
        </footer>
      </div>
    </div>
  );
};

export default App;

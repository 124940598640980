import React from 'react';
import TechsicoLogo from './TechsicoLogo';
import TechsicoNativeLogo from './TechsicoNativeLogo';
import TechsicoIncLogo from './TechsicoIncLogo';

export const TECHSICO_LOGO = {
  TECHSICO: 'Techsico',
  TECHSICO_NATIVE: 'Techsico Native',
  TECHSICOINC: 'Techsico Inc'
};

const TechsicoPortal = ({
  logo = TECHSICO_LOGO.TECHSICO,
  title = 'Techsico',
  subtitle = 'BranchName',
  underlineColor = 'red-500',
  link = null
}) => {
  const [isHover, setIsHover] = React.useState(false);
  return (
    <div
      className="relative py-17 flex flex-col justify-center items-center lg:py-19 1.125xl:py-31 3xl:py-40"
      onMouseOver={() => {
        setIsHover(true);
        document.body.style.cursor = "pointer";
      }}
      onMouseOut={() => {
        setIsHover(false);
        document.body.style.cursor = "default";
      }}
      onClick={() =>{
        if(link !== null){
          window.open(link);
        }
      }}
    >
      <div className="z-10">
        {logo === TECHSICO_LOGO.TECHSICO_NATIVE ? (
          <TechsicoNativeLogo
            className="w-6 h-6 lg:w-8 lg:h-8 1.125xl:w-14 1.125xl:h-14 3xl:w-20 3xl:h-20"
            color={isHover ? 'white' : 'techsicoGray-900'}
          />
        ) : logo === TECHSICO_LOGO.TECHSICOINC ? 
        (
          <TechsicoIncLogo
            className="w-6 h-6 lg:w-8 lg:h-8 1.125xl:w-14 1.125xl:h-14 3xl:w-20 3xl:h-20"
            color={isHover ? 'white' : 'techsicoGray-900'}
          />
        ) : (
          <TechsicoLogo
            className="w-6 h-6 lg:w-8 lg:h-8 1.125xl:w-14 1.125xl:h-14 3xl:w-20 3xl:h-20"
            color={isHover ? 'white' : 'techsicoGray-900'}
          />
        )}
      </div>
      <div className="z-10">
        <h2
          className={`font-classicapro font-bold uppercase text-center text-xxs leading-3 mt-1 lg:text-sm 1.125xl:text-xl 3xl:text-3xl 3xl:mt-3 ${
            isHover ? 'text-white' : 'text-techsicoGray-900'
          }`}
        >
          {title}{' '}
          <span className="font-rubik text-lg leading-4 block mt-0.5 lg:text-2xl lg:leading-4 1.125xl:text-4xl 1.125xl:leading-6 3xl:text-6xl 3xl:leading-12">
            {subtitle}
          </span>
        </h2>
        <div
          className={`bg-${underlineColor} mx-auto h-1.5 transition-width duration-300 mt-2.5 lg:mt-4 3xl:h-2.5 3xl:mt-5 ${
            isHover ? 'w-0' : 'w-25 lg:w-32 1.125xl:w-44 3xl:w-64'
          }`}
        ></div>
      </div>
      <div
        className={`bg-${underlineColor} absolute z-0 mx-auto transition-area ${
          isHover ? 'w-full h-full' : 'w-0 h-0'
        }`}
      ></div>
    </div>
  );
};

export default TechsicoPortal;

import React from 'react';
import Map from '../components/Map';
import useBreakPoint from '../hooks/useBreakPoint';

const centerCoordinates = { lat: 35.686974, lng: -108.537798 };
const techsicoLocations = [
  {
    name: 'Headquarters',
    address: '5524 E 9th St',
    city: 'Tulsa',
    state: 'OK',
    zipCode: 74112,
    coordinates: { lat: 36.149520, lng: -95.914578 },
  },
  {
    name: 'San Diego',
    address: '1020 Linda Vista',
    city: 'San Marcos',
    state: 'CA',
    zipCode: 92078,
    coordinates: { lat: 33.13955, lng: -117.18733 },
  },
  {
    name: 'Los Angeles',
    address: '2161 Railroad St',
    city: 'Corona',
    state: 'CA',
    zipCode: 92078,
    coordinates: { lat: 33.89009, lng: -117.60661 },
  },
  {
    name: 'Houston',
    address: '22702 Commercial Ln',
    city: 'Tomball',
    state: 'TX',
    zipCode: 77375,
    coordinates: { lat: 30.0898, lng: -95.57639 },
  },
  {
    name: 'Austin/San Antonio',
    address: '4288 FM 482, Suite 400',
    city: 'New Braunfels',
    state: 'TX',
    zipCode: 78132,
    coordinates: { lat: 29.66751, lng: -98.16918 },
  },
  {
    name: 'Las Vegas',
    address: '489 Victory Rd',
    city: 'Henderson',
    state: 'NV',
    zipCode: 89015,
    coordinates: { lat: 36.03238, lng: -114.99654 },
  },
  {
    name: null,
    address: '5516 East 9th St',
    city: 'Techsico Native',
    state: 'OK',
    zipCode: 74112,
    coordinates: { lat: 36.14955, lng: -95.91484 },
  },
  {
    name: null,
    address: '80 S Pioneer Rd',
    city: 'Salt Lake City',
    state: 'UT',
    zipCode: 84104,
    coordinates: { lat: 40.76078, lng: -111.891045 },
  },
  {
    name: null,
    address: '910 South Hudson Ave',
    city: 'Techsico Enterprise',
    state: 'OK',
    zipCode: 74112,
    coordinates: { lat: 36.14941, lng: -95.91395 },
  },
];

const Locations = () => {
  const mapRef = React.useRef();
  const buttonRef = React.useRef();

  const { isMd, isLg, 'is1.125xl': is1_125xl } = useBreakPoint();

  return (
    <div>
      <h2 className="bg-techsicoBlue-800 text-white font-rubik text-xl pl-5 py-4 md:text-2xl md:pl-9 md:py-5.5 lg:text-3.5xl 1.125xl:text-5xl 3xl:text-6xl 3xl:leading-20 lg:py-7.5 lg:pl-30 1.125xl:py-9 1.125xl:pl-38 3xl:py-12 3xl:pl-48">
        Locations
      </h2>
      <div className="relative">
        <Map
          ref={mapRef}
          center={centerCoordinates}
          zoom={is1_125xl ? 6 : isLg ? 5 : isMd ? 5 : 4}
          scrollWheelZoom={false}
          markers={techsicoLocations.map(({ coordinates, address }) => ({
            coordinates,
            address,
          }))}
          className="z-10 h-144 md:h-180 lg:h-184 1.125xl:h-screen"
        />
        <div className="p-5 z-20 absolute bottom-0 bg-gray-900 w-full h-42 flex overflow-x-auto overflow-y-hidden bg-opacity-60 md:pl-9 lg:pl-5 lg:w-70 lg:h-full lg:ml-9 lg:w-84 lg:ml-30 1.125xl:p-8 1.125xl:w-128 1.125xl:ml-38 3xl:w-144 3xl:ml-48 3xl:p-8 lg:grid lg:grid-cols-2 3xl:block">
          {techsicoLocations.map(
            ({ name, address, city, state, zipCode, coordinates }) => (
              // card
              <div
                key={address}
                className={`flex flex-col 3xl:flex-row ${
                  name === 'Headquarters'
                    ? 'lg:flex-row lg:col-span-2'
                    : 'lg:flex-col'
                }`}
              >
                {/* words */}
                <div
                  className={`w-38 mr-4.5 md:mr-7 lg:mr-0 lg:w-max 3xl:mb-4 3xl:flex-grow ${
                    name === 'Headquarters'
                      ? 'lg:flex-grow'
                      : 'lg:mb-2 1.125xl:mb-5'
                  }`}
                >
                  {/* name */}
                  <h3
                    className={`text-rubik text-white font-bold ${
                      name === 'Headquarters'
                        ? 'lg:text-3.5xl 1.125xl:text-4.3xl 3xl:text-5xl 2xl:mb-4'
                        : '1.125xl:text-2xl 3xl:text-3xl'
                    }`}
                  >
                    {name === null
                      ? `${city}`
                      : name === 'Headquarters'
                      ? `${name}`
                      : `${name}`}
                  </h3>
                  {/* address */}
                  {address === 'TBD' ? (
                    <p className="text-montserrat text-white text-xs 1.125xl:text-base my-2 lg:mt-3 1.125xl:mt-2 1.125xl:mb-0">
                      TBD
                    </p>
                  ) : (
                    <address
                      className={`not-italic text-montserrat text-white text-xs my-2 lg:mt-3 1.125xl:mt-2 1.125xl:-mb-2 3xl:mb-1 ${
                        name === 'Headquarters'
                          ? 'lg:text-base 1.125xl:text-2xl'
                          : '1.125xl:text-base'
                      }`}
                    >
                      {address}
                      <br />
                      {`${city}, ${state} ${zipCode}`}
                    </address>
                  )}
                </div>
                {/* view button */}
                <div className="relative">
                  {address !== 'TBD' && (
                    <button
                      className={`uppercase font-bold text-white bg-techsicoBlue-800 hover:bg-techsicoBlue-500 px-4 border-4 border-white rounded-2xl py-2 3xl:absolute 3xl:bottom-4 3xl:right-0 3xl:py-4 ${
                        name === 'Headquarters'
                          ? 'lg:mt-14 1.125xl:mt-22 3xl:mt-0'
                          : ''
                      }`}
                      onClick={() => {
                        mapRef.current.zoomInTo(coordinates);
                        buttonRef.current.style.display = 'block';
                      }}
                    >
                      View
                    </button>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <button
          ref={buttonRef}
          className="z-30 hidden uppercase font-bold text-white bg-techsicoRed-700 hover:bg-techsicoRed-900 px-4 border-4 border-white rounded-2xl absolute right-4 bottom-46 md:right-8 md:bottom-60 py-2 lg:bottom-8 lg:right-8 3xl:bottom-8 3xl:right-8 3xl:py-4"
          onClick={() => {
            mapRef.current.zoomOutTo(centerCoordinates);
            buttonRef.current.style.display = 'none';
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default Locations;

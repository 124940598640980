import React from 'react';

const useMediaQuery = mediaQueryString => {
  const [matches, setMatches] = React.useState(false);

  React.useEffect(() => {
    // e.g., '(max-width: 540px)' => object which can detech matches and monitor changes
    const mediaQueryList = window.matchMedia(mediaQueryString);
    // initial state of matching or not
    setMatches(mediaQueryList.matches);
    // update state
    const changeEventHandler = event => setMatches(event.matches);
    // monitor changes
    mediaQueryList.addEventListener('change', changeEventHandler);
    // clean up
    return () =>
      mediaQueryList.removeEventListener('change', changeEventHandler);
  }, [mediaQueryString]);

  return matches;
};

export default useMediaQuery;

import React from 'react';
import BannerContext from '../contexts/BannerContext';

const Banner = ({ children }) => {
  const [hoverItemOrder, setHoverItemOrder] = React.useState(null);
  return (
    <BannerContext.Provider value={[hoverItemOrder, setHoverItemOrder]}>
      <div className="min-h-main-sm grid grid-cols-1 grid-rows-3 md:min-h-0 md:h-80 md:flex md:flex-rows md:justify-center lg:h-112 1.125xl:h-164 3xl:min-h-main-3xl">
        {children}
      </div>
    </BannerContext.Provider>
  );
};

export default Banner;

import React from 'react';
import TechsicoLogo from '../components/TechsicoLogo';
import TechsicoNativeLogo from '../components/TechsicoNativeLogo';
import TechsicoIncLogo from '../components/TechsicoIncLogo';
import Banner from '../components/Banner';
import BannerItem from '../components/BannerItem';
import Map from '../components/Map';
import Card from '../components/Card';
import useBreakPoint from '../hooks/useBreakPoint';
import { Link } from 'react-router-dom';

const techsicoHeadquartersCoordinates = { lat: 36.149429, lng: -95.913879 };
const centerCoordinates = {
  sm: { lat: 36.141, lng: -95.91388 },
  md: { lat: 36.15, lng: -95.935 },
  lg: { lat: 36.15, lng: -95.945 },
  '3xl': { lat: 36.15, lng: -95.924 },
};

const Home = () => {
  const { isMd, isLg, is3xl } = useBreakPoint();
  return (
    <div>
      <Banner>
      <BannerItem
          mdOrder="1"
          title="Enterprise"
          subtitle="Techsico"
          underlineColor="enterpriseRed-500"
          text="Techsico Enterprise focuses on excellence in voice and data, paging and intercoms, audio and video, telephony and network engineering, and more. With trusted partners and certified employees, each project is approached with care and expertise from design to installation."
          logo={
            <TechsicoLogo
              className="w-10 h-10 1.125xl:w-14 1.125xl:h-14 3xl:w-20 3xl:h-20"
              color="white"
            />
          }
          className="bg-cover bg-hero-enterprise-sm md:bg-hero-enterprise-md lg:bg-hero-enterprise-lg"
          link={process.env.REACT_APP_TES_LINK}
        ></BannerItem>
          <BannerItem
            mdOrder="2"
            title="Inc."
            subtitle="Techsico"
            underlineColor="wirelessRed-50"
            text="Techsico Inc. partners with telecommunication companies to bring innovative solutions to small cell and tower infrastructure needs. Cutting edge technology allows for superior craftsmanship and customer satisfaction. Construction methodoliges at Techsico Wireless focus on safety, efficiency, and reliability."
            logo={
              <TechsicoIncLogo
                className="w-10 h-10 1.125xl:w-14 1.125xl:h-14 3xl:w-20 3xl:h-20"
                color="white"
              />
            }
            className="bg-cover bg-hero-wireless-sm md:bg-hero-wireless-md lg:bg-hero-wireless-lg"
            link={process.env.REACT_APP_TOW_LINK}
          ></BannerItem>
        <BannerItem
          mdOrder="3"
          title="Native"
          subtitle="Techsico"
          underlineColor="nativeBlue-200"
          text="Techsico Native delivers quality work with access control systems, surveillance systems, fire alarm systems, audio and video, and more. Certifications and training in top-of-the-line technologies lead to reliable, high quality solutions to customer projects."
          logo={
            <TechsicoNativeLogo
              className="w-10 h-10 1.125xl:w-14 1.125xl:h-14 3xl:w-20 3xl:h-20"
              color="white"
            />
          }
          className="bg-cover bg-hero-native-sm md:bg-hero-native-md lg:bg-hero-native-lg"
          link={process.env.REACT_APP_TNT_LINK}
        ></BannerItem>
        <BannerItem
          mdOrder="4"
          title="Solar"
          subtitle="Techsico"
          underlineColor="techsicoBlue-900"
          text="Techsico Solar"
          logo={
            <TechsicoLogo
                className="w-10 h-10 1.125xl:w-14 1.125xl:h-14 3xl:w-20 3xl:h-20"
                color="white"
              />
          }
          className="bg-cover bg-hero-solar-sm md:bg-hero-solar-md lg:bg-hero-solar-lg"
          link={process.env.REACT_APP_SOL_LINK}
        ></BannerItem>
      </Banner>
      {/* intro */}
      <section>
        <Card
          title={
            isMd
              ? 'If It’s Technology, It’s TECHSICO'
              : `If It’s Technology,\nIt’s TECHSICO`
          }
          content={`We work at the forefront of innovation and technology to take products from an idea all the way to the end consumer. Our knowledgeable teams of experts help build, enhance, and maintain your networks. Techsico Wireless partners with telecommunication companies to bring the latest 5G technology to their consumers. Techsico Enterprise focuses on low-voltage solutions including security, fire alarm systems, life safety systems, audio and video, and network engineering.
          
          From access controls to voice and data, we are here to meet your technological needs. We can help manage demands placed on networks, design systems that will give you security or access control systems, and much more. No matter which field your needs fall under, we are ready to go to work for you.
          
          We are committed to providing you with the best service, whether the service is for your home, workplace, school, or house of worship. From continuous training in fast-growing fields to partnering with the best companies of the industry, Techsico does what we can to ensure a job well done. We take pride in what we do.`}
          color="techsicoRed-700"
          hoverColor="techsicoRed-900"
        />
      </section>
      {/* map */}
      <section className="relative">
        <Map
          center={
            is3xl
              ? centerCoordinates['3xl']
              : isLg
              ? centerCoordinates.lg
              : isMd
              ? centerCoordinates.md
              : centerCoordinates.sm
          }
          zoom={is3xl ? 15 : 14}
          scrollWheelZoom={false}
          markerCoordinates={techsicoHeadquartersCoordinates}
          popupContent={`5524 East <br /> 9th St`}
          className="z-10 h-144 md:h-80 lg:h-112 1.125xl:h-132 3xl:h-152"
        />
        <div className="p-5 z-20 absolute bottom-0 bg-gray-900 w-full bg-opacity-60 md:w-70 md:h-full md:ml-24 lg:w-96 lg:ml-30 1.125xl:w-140 1.125xl:ml-40 3xl:w-192 3xl:ml-48">
          <TechsicoLogo
            color="white"
            className="h-7 w-7 mx-auto md:mt-7 lg:w-10 lg:h-10 lg:mt-8 1.125xl:w-14 1.125xl:h-14 1.125xl:mt-19 3xl:w-20 3xl:h-20 3xl:mt-20"
          />
          <h2 className="text-center text-white uppercase font-classicapro mt-2 text-base leading-4 lg:text-lg lg:leading-5 1.125xl:text-2xl 3xl:text-3xl">
            Techsico{' '}
            <span className="block text-2xl leading-6 font-rubik mt-0.5 lg:leading-7.5 lg:text-4xl 1.125xl:text-5xl 3xl:text-6xl">
              Headquarters
            </span>
          </h2>
          <hr className="h-1 w-40 mx-auto bg-white m-3.5 md:w-40 lg:mt-5 lg:w-64 1.125xl:h-2" />
          <address className="text-center text-white font-montserrat not-italic mt-3.5 lg:text-xl lg:mt-7 1.125xl:text-2xl 3xl:text-3xl 3xl:leading-10">
            5524 East 9th St
            <br />
            Tulsa, OK 74112
          </address>
          <div className="flex justify-center">
            <Link
              to="/locations"
              className="font-rubik text-sm uppercase text-white text-center inline-block mx-auto bg-techsicoRed-700 hover:bg-techsicoRed-900 p-3.5 border-4 rounded-2xl mt-4 lg:text-lg lg:mt-8 1.125xl:text-xl 3xl:text-3xl"
            >
              View other locations
            </Link>
          </div>
        </div>
      </section>
      {/* history */}
      {/* With our growth comes our commitment to excellence.  */}
      <section>
        <Card
          title={
            isMd
              ? 'Technology For All Customers'
              : `Technology For\nAll Customers`
          }
          content={`Founded in 2001, the Techsico family of companies is a consumer-driven technology company headquartered in Tulsa, OK. Techsico continues to grow the breadth of services we offer and the geographical footprint we serve. Through our commitment to excellence and dedication to our customers, we continue to grow and develop to better serve you. By keeping up with technological changes and branching out to more locations, we are able to serve our community better than ever. We strive to leave every customer more than satisfied with the work we have done.
          
          We have one mission: to deliver the highest standards of products and services to ensure unmatched customer satisfaction every time.`}
          color="techsicoBlue-800"
          hoverColor="techsicoBlue-500"
        />
      </section>
    </div>
  );
};

export default Home;

import React from 'react';
import BannerContext from '../contexts/BannerContext';

const BannerItem = ({
  mdOrder = 1,
  title = 'title',
  subtitle = 'subtitle',
  logo = '',
  underlineColor = 'red-500',
  text = '',
  className,
  link = null
}) => {
  const [currentHoverItemIndex, setcurrentHoverItemIndex] = React.useContext(
    BannerContext
  );
  return (
    <div
      className={`${className} transition-width duration-300 md:order-${mdOrder} ${
        currentHoverItemIndex === mdOrder
          ? 'md:w-3/5'
          : currentHoverItemIndex === null
          ? 'md:w-2/6'
          : 'md:w-1/5'
      }`}
      onMouseOver={() => {
        document.body.style.cursor = "pointer";
        setcurrentHoverItemIndex(mdOrder);
      }}
      onMouseOut={() => {
        document.body.style.cursor = "default";
        setcurrentHoverItemIndex(null);
      }}
      onClick={() =>{
        if(link !== null){
          window.open(link);
        }
      }}
    >
      {/* mask */}
      <div className="bg-gray-800 bg-opacity-60 h-full p-5 flex flex-col justify-center items-center relative">
        <div
          className={`transition-opacity duration-300 ${
            currentHoverItemIndex !== mdOrder && currentHoverItemIndex !== null
              ? 'md:opacity-0'
              : 'md:opacity-100'
          }`}
        >
          {logo}
        </div>
        <div
          className={`${
            currentHoverItemIndex !== mdOrder && currentHoverItemIndex !== null
              ? 'md:-rotate-90'
              : 'md:rotate-0'
          } transition-transform duration-300 transform`}
        >
          <h2 className="font-classicapro uppercase text-white text-center text-base leading-4 mt-2 lg:text-lg lg:leading-5 1.125xl:text-2xl 3xl:text-3xl">
            {subtitle}{' '}
            <span className="font-rubik text-3xl leading-7.5 block mt-0.5 lg:text-4xl lg:leading-9 1.125xl:text-5xl 3xl:text-7xl">
              {title}
            </span>
          </h2>
          <div
            className={`bg-${underlineColor} mx-auto h-1.5 w-32 md:w-24 mt-3 lg:h-2 lg:w-28 1.125xl:w-44 3xl:w-64 3xl:h-2.5`}
          ></div>
        </div>
        <p
          className={`max-w-full transition-opacity ease-in absolute hidden text-xxs text-center leading-4 text-white font-montserrat mt-4 md:block md:pt-32 md:w-96 md:h-12 lg:text-sm lg:mt-6 lg:leading-5 lg:w-132 lg:h-13 lg:pt-36 xl:w-164 1.125xl:text-base 1.125xl:mt-8 1.125xl:leading-6 1.125xl:w-192 1.125xl:h-19 1.125xl:pt-48 3xl:text-1.5xl 3xl:leading-9 3xl:w-240 3xl:h-38 3xl:absolute 3xl:pt-64 ${
            currentHoverItemIndex === mdOrder ? 'opacity-100 ' : 'opacity-0'
          }`}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default BannerItem;
